import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, ImageOverlay, VideoOverlay, Tooltip, useMap } from "react-leaflet";
import { Icon, LatLngBounds } from "leaflet";
import { useRef, useEffect, useState } from "react";
import ReactPlayer from "react-player/file";

function Markers({ data, icon, onClick }) {
    return (
        data.length > 0 &&
        data.map((marker) => (
            <Marker
                eventHandlers={{
                    click: () => {
                        onClick(marker.type, marker.transition, marker.data);
                    },
                }}
                key={marker.id}
                position={[marker.latitude, marker.longitude]}
                icon={icon}
            >
                <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
                    {marker.name}
                </Tooltip>
            </Marker>
        ))
    );
}


function MapController({ center, zoom }) {
    const map = useMap();
    useEffect(() => {
        map.flyTo(center, zoom, { 
            animate: true,
            duration: 2
        });
    }, [center, zoom, map]);
    return null;
}

function Map({ location, onClose }) {
    const videoRef = useRef(null);

    const [dataType, setDataType] = useState(0)
    const [transitionUrl, setTransitionUrl] = useState("");
    const [dataUrl, setDataUrl] = useState("");
    const [showStream, setShowStream] = useState(false);
    const [mapCenter, setMapCenter] = useState([location.center_lat, location.center_lng]);
    const [mapZoom, setMapZoom] = useState(location.zoom);

    const icon = new Icon({
        iconUrl: require("./images/marker.png"),
        iconSize: [38, 38],
    });

    const center = [location.center_lat, location.center_lng];
    const latLngBounds = new LatLngBounds([
        [location.min_lat, location.min_lng],
        [location.max_lat, location.max_lng],
    ]);

    useEffect(() => {
        if (videoRef.current) {
            const endTransition = () => {
                if (dataType === 0) {
                    setShowStream(true);
                } else if (dataType === 1) {
                    window.open(dataUrl, "_self");
                }
            };
            const element = videoRef.current._image;
            element.addEventListener("ended", endTransition);
        }
    }, [dataUrl]);

    const markerCallback = (dataType, transitionUrl, dataUrl) => {
        setDataType(dataType);
        setTransitionUrl(transitionUrl);
        setDataUrl(dataUrl);
        setMapCenter(center);
        setMapZoom(location.zoom);
    };

    return (
        <div className="map">
            {!showStream && (
                <MapContainer
                    center={mapCenter}
                    zoom={mapZoom}
                    attributionControl={false}
                    zoomControl={false}
                    zoomSnap={0.5}
                    zoomDelta={0.5}
                    bounceAtZoomLimits={false}
                    minZoom={location.zoom - 0.5}
                    maxZoom={location.zoom + 2}
                    maxBounds={latLngBounds}
                    maxBoundsViscosity={1}
                >
                    <MapController center={mapCenter} zoom={mapZoom} />
                    <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"></TileLayer>
                    <ImageOverlay url={location.data} bounds={latLngBounds}></ImageOverlay>
                    {transitionUrl === "" ? (
                        <Markers data={location.markers} icon={icon} onClick={markerCallback}/>
                    ) : (
                        <VideoOverlay 
                            url={transitionUrl} 
                            bounds={latLngBounds} 
                            loop={false} 
                            keepAspectRatio={false} 
                            className="transition" 
                            ref={videoRef}
                        />
                    )}
                </MapContainer>
            )}
            {dataUrl !== "" && (
                <ReactPlayer 
                    url={dataUrl} 
                    playing={true} 
                    controls={false} 
                    muted={true} 
                    width="100%" 
                    height="100%" 
                    playsinline={true} 
                    config={{hlsVersion: "1.5.13"}}
                />
            )}
            <img className="close" src={require("./images/close.png")} alt="close" onClick={onClose}></img>
        </div>
    );
}

export default Map;
