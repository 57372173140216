import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import MoonLoader from "react-spinners/MoonLoader";
import Swal from "sweetalert2";
import Map from "./Map";

function App() {
    const videoRef = useRef(null);
    const photoRef = useRef(null);

    const [stream, setStream] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [location, setLocation] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(0.5);
    const [maxZoom, setMaxZoom] = useState(1);

    const handleZoomChange = (event) => {
        console.log("zoomValue: ", event.target.value);
        setZoomLevel(event.target.value);
    };

    function updateZoomSettings(zoomValue) {
        if (videoRef && videoRef.current && videoRef.current.srcObject) {
            const videoTrack = videoRef.current.srcObject.getVideoTracks()[0];
            const settings = videoTrack.getSettings();
            console.log("settings=>", settings);
            // Apply the zoom level to the camera track if zoom is supported
            if (videoTrack.applyConstraints && settings.zoom !== undefined) {
                console.log("Updating zoom settings.");
                videoTrack
                    .applyConstraints({
                        advanced: [{ zoom: zoomValue }],
                    })
                    .catch((err) => console.error("Error applying zoom:", err));
            }
        }
    }

    const getVideo = () => {
        if (stream) {
            stream.getTracks().forEach((track) => {
                track.stop();
            });
        }

        if (!showMap) {
            navigator.mediaDevices
                .getUserMedia({
                    audio: false,
                    video: {
                        facingMode: "environment",
                        width: { ideal: 960 },
                        height: { ideal: 720 },
                    },
                })
                .then((stream) => {
                    let video = videoRef.current;
                    const videoTrack = stream.getVideoTracks()[0];
                    console.log("videoTrack=> ", videoTrack);
                    const capabilities = videoTrack.getCapabilities();
                    // Check if the device supports zoom
                    if (capabilities.zoom) {
                        console.log("capabilities.zoom=> ", capabilities.zoom);
                        setMaxZoom(capabilities.zoom.max); // Get the maximum zoom level
                        setZoomLevel(capabilities.zoomValue);
                    }
                    video.srcObject = stream;

                    var playPromise = video.play();

                    if (playPromise !== undefined) {
                        playPromise
                            .then((_) => {
                                // Automatic playback started!
                                // Show playing UI.
                                setStream(stream);
                            })
                            .catch((error) => {
                                // Auto-play was prevented
                                // Show paused UI.
                            });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    function addBlankPageLoader(newWindow) {
        if (newWindow) {
            // Write custom HTML with a loader into the new tab
            newWindow.document.write(`
              <html>
                <head>
                  <title>Loading...</title>
                  <style>
                    body { display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; background:rgba(0,0,0, 0.7);}
                    .loader {
                      border: 16px solid #f3f3f3;
                      border-radius: 50%;
                      border-top: 16px solid #3498db;
                      margin:auto;
                      width: 120px;
                      height: 120px;
                      -webkit-animation: spin 2s linear infinite; /* Safari */
                      animation: spin 2s linear infinite;
                    }
                    @-webkit-keyframes spin {
                      0% { -webkit-transform: rotate(0deg); }
                      100% { -webkit-transform: rotate(360deg); }
                    }
                    @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                    }
                    .whiteText{
                      color:white;
                      font-size: 30px;
                      text-align: middle;
                      margin-bottom: 20px;
                    }
                  </style>
                </head>
                <body>
                    <div style="margin:30vh auto auto auto;">
                        <div class="whiteText">Processing Image...</div>
                        <div class="loader"></div>
                    </div>
                </body>
              </html>
            `);

            newWindow.document.close(); // Ensure the document is fully loaded in the new tab
        }
    }

    const takePhoto = () => {
        const newWindow = window.open("", "_blank");
        addBlankPageLoader(newWindow);
        let video = videoRef.current;
        let photo = photoRef.current;

        const width = video.videoWidth;
        const height = video.videoHeight;

        photo.width = width;
        photo.height = height;

        let ctx = photo.getContext("2d");
        ctx.drawImage(video, 0, 0, width, height);

        photo.toBlob((blob) => {
            uploadPhoto(blob, newWindow);
        });
        ctx.clearRect(0, 0, photo.width, photo.height);
    };

    const uploadPhoto = (blob, newWindow) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", blob);

        axios
            .post("https://backend.vl.sg/search", formData)
            //axios.post("http://localhost:8000/search", formData)
            .then((res) => {
                setLoading(false);
                if (res.data.type === 0) {
                    newWindow.close();
                    setShowMap(true);
                    setLocation(res.data);
                } else if (res.data.type === 1) {
                    newWindow.location.href = res.data.data;
                }
            })
            .catch(function (error) {
                setLoading(false);
                newWindow.close();
                if (error.response && error.response.status === 400) {
                    Swal.fire({
                        title: "No match found!",
                        html: "Try again with a clearer image.<br>" + "Make sure the landmark is centered and well-lit.<br>" + "Try to fill most of the frame with the landmark.",
                        icon: "info",
                        confirmButtonText: "OK",
                    });
                } else {
                    Swal.fire({
                        title: "Error!",
                        html: "Something went wrong.<br>" + error.message,
                        icon: "error",
                        confirmButtonText: "Close",
                    });
                }
            });
    };

    const closeMap = () => {
        setShowMap(false);
    };

    const initElements = () => {
        const fileInput = document.getElementById("upload-btn");

        fileInput.addEventListener("change", (e) => {
            const newWindow = window.open("", "_blank");
            const f = e.target.files[0];
            uploadPhoto(f, newWindow);
        });
    };

    const handleVisibilityChange = () => {
        if (!document.hidden) {
            getVideo();
        }
        /*if (document.visibilityState === "visible") {
            reapplyZoom();
        }*/
    };

    const handleFocus = () => {
        getVideo();
    };

    useEffect(() => {
        getVideo();
        if (!showMap) {
            initElements();
        }

        // Add event listeners for visibility change and focus
        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("focus", handleFocus);

        // Cleanup function
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            window.removeEventListener("focus", handleFocus);
        };
    }, [showMap]);

    /*useEffect(() => {
        updateZoomSettings(zoomLevel);
    }, [zoomLevel]);*/

    // Reapply the stored zoom level when the tab is focused again
    const reapplyZoom = () => {
        const videoTrack = videoRef.current.srcObject.getVideoTracks()[0];
        const settings = videoTrack.getSettings();
        if (settings.zoom !== undefined && settings.zoom !== zoomLevel) {
            setZoomLevel(zoomLevel); // Reapply the zoom from state
        }
    };

    return (
        <div className="App">
            {!showMap ? (
                <div className="camera">
                    <video ref={videoRef} playsInline={true}></video>
                    <canvas ref={photoRef}></canvas>
                    <input type="file" id="upload-btn" hidden accept="image/*" />
                    <div className="control">
                        {/* <label htmlFor="upload-btn" className="uploadLabel">
                            <img className="uploadButton" src={require("./images/upload.png")} alt="button"></img>
                        </label> */}
                        <img className="scanButton" src={require("./images/scan.png")} alt="button" onClick={takePhoto}></img>

                        {/*<input type="range" className="zoomSlider" min="0.1" max={maxZoom} step="0.1" value={zoomLevel} onChange={handleZoomChange} />*/}
                    </div>
                    <img id="uploadedImg" alt="uploaded" hidden />
                </div>
            ) : (
                <Map location={location} onClose={closeMap}></Map>
            )}
            <div className={"loading " + (loading ? "display" : "")}>
                <MoonLoader color="#ffffff" size={50} loading={loading}></MoonLoader>
            </div>
        </div>
    );
}

export default App;
